<template>
  
                <div v-if="!viewmode">
                <span class="editbn" @click="$parent.form_visible(names)" v-if="!$parent.form_getvisible(names)"></span>
                <span class="okbn" v-else @click="$parent.form_change">✓ OK</span>
                </div>
</template>
<style scoped lang="scss">
.editbn{
  display: inline-block;
  width:2.3em;
  height:2.3em;
  border-radius: .3em;
  color: #fff;
  text-decoration: none;
  margin: 0 .2em .2em .2em;
  vertical-align: middle;
  background:  url("../assets/images/editable.png") no-repeat center / contain;
  cursor: pointer;
}

.editbn:hover{
    background-color: rgb(217, 220, 252);
}

.okbn{
    margin-top: .3em;
    margin-bottom: .3em;
    cursor: pointer;
    color:#fff;
    font-weight: bold;
    padding: 0.2em 1.5em;
    margin-right: .3em;
    display: inline-block;
    background: #0045F2;
}
.okbn:hover{
    background-color: rgb(217, 220, 252);
}
</style>
<script>
export default {
  props: ['names'],
   computed:{
  },
  data: function () {
    return {
    };
  },
  methods:{
  },
  created: function () {
  }
}
</script>