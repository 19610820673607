<template>
  <div :class="rowclass">
    <div v-if="!formmode_self">
      {{pretext}}{{getvalue}}
    </div>
    <div v-else>
      
     
     <label v-if="labelname">{{labelname}}</label>
    <input type="text"  
      :value="getvalue"
      @change="updateCheck($event.target.value)"
      :size="size"
      >
     

      
      </div><!--@blura="form_unvisible"-->
  </div>
</template>
<style scoped lang="scss">
$sp: 680px; // スマホ
@mixin sp {
    @media (max-width: ($sp)) {
        @content;
    }
}
    label{
      padding-top: .4em;
      width: 8em;
      display: inline-block;
      font-size: .8em;
      margin-right: .5em;
      vertical-align: top;
    @include sp{
      display: block;
      width: 100%;
    }
    }
    .editform{
      display: block;
      margin-bottom: .3em;
    }
    .editform_inline{
      display: inline-block;
    }
    .edittext{
      display: inline-block;
    }input{
      max-width: 85vw;
    }
    .changed1 input{
      background: rgb(212, 250, 230);
    }
</style>
<script>
export default {
  props: ['labelname','formmode','name','display','pretext','size'],
  watch:{/*
    formmode:function(newVal,oldVal){
      console.log(newVal,oldVal);
      if(newVal){
      this.$parent.form_visible(this.names);
      }
    }*/
  },
   computed:{
     
     rowclass:function(){
      // edittext:!formmode_self,editform:formmode_self
       let classes={changed:this.form_changed};
        if(!this.formmode_self){
          classes.edittext=true;
        }else if(this.display=="inline"){
          classes.editform_inline=true;
        }else{
          classes.editform=true;
        }
        return classes;
     },
    getvalue:{
      get(){
        return this.$parent.form_getvalue(this.name);
      }
    },
    form_changed:function(){
      //console.log("changed?",[this.name]);
      return this.$parent.form_changed([this.name]);
    },
    formmode_self:{
      get(){
        return this.$parent.form_getvisible([this.name]);
      },
      set(newVal){
        this.$emit("form_unvisible", {newVal:newVal,name:[this.name]})
      }
    },
  },
  data: function () {
    return {
    };
  },
  methods:{
    /*
    form_unvisible:function(){
      console.log("form_unvisible")
      this.$emit("form_unvisible",[this.name]);
      this.$parent.form_unvisible(this.names);
    },
    */
    updateCheck:function(val){
      this.$parent.form_prechange(this.name,val);
      //その値が更新したものかどうか
      console.log();
    },
  },
  created: function () {
  }
}
</script>