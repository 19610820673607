<template>
  <transition name="fade">
    <div class="statusbox">
    <div v-if="loaded">

      
    <div class="detailbox profiles">
      
      <span v-if="flow=='confirm'" class="backlink"><router-link @click.native="scrollToTop" :to="{ path: '/item/' + label + '/sender', query: { rd: 'back' }}">編集</router-link></span>

            <h3>ご注文者様情報</h3>

      <dl><dt>ご注文者様名 <ordereditformbns :names="['sei','mei']" /></dt>
              <dd :class="{changed:form_changed(['sei','mei'])}">
                  <!--{{fields.sei.value}} {{fields.mei.value}}-->
                <ordereditform name="sei" display="inline" />　<ordereditform name="mei" display="inline" />
                
               
                

              </dd>
            </dl>
            <dl v-if="fields.sei_kana.value||fields.mei_kana.value">
              <dt>ご注文者様名ふりがな 
                <ordereditformbns :names="['sei_kana','mei_kana']" /></dt>
              <dd :class="{changed:form_changed(['sei_kana','mei_kana'])}">
                
                <ordereditform name="sei_kana" display="inline" />　<ordereditform name="mei_kana" display="inline" />

                
                
                </dd>
            </dl>
            
            <dl>
              <dt>ご注文者様住所<ordereditformbns :names="['zip','state','city','street','building','other']" /></dt>
              <dd :class="{changed:form_changed(['zip','state','city','street','building','other'])}">
                      <ordereditform name="zip" labelname="郵便番号" pretext="〒" display="inline" size="8" /><br>

                      <ordereditform name="state" labelname="都道府県" size="8"  />
                      <ordereditform name="city" labelname="市町村" size="15"  />
                      <ordereditform name="street" labelname="地域・番地" size="60" />　<ordereditform name="building" labelname="アパート・マンション・建物名" size="60" />
                      <ordereditform name="other" labelname="様方・会社名" size="60" />



                   
                   
                   </dd>
            </dl>


            <dl>
              <dt>ご注文者様電話番号<ordereditformbns :names="['tel']" /></dt></dt>
              <dd :class="{changed:form_changed(['tel'])}">
               <ordereditform name="tel" size="20"  /></dd>
            </dl>

            <dl v-if="fields.email.value">
              <dt>メールアドレス</dt>
              <dd>
                {{ fields.email.value }}</dd>
            </dl>


    </div>


      <div
        class="deliverone"
        v-for="(mydeliver, i) in fields.mydeliver.subfields"
        :key="i"
      >
      <div
        class="itemone"
        v-for="(item, n) in orderData.fields.items.subfields"
        :key="n" v-if="item.deliver.value==i">

        <h1 v-if="fields.mydeliver.subfields.length>1">{{ n + 1 }}枚目の内容</h1>
        <h1 v-else>ご注文内容</h1>
            <h4>{{orderData.fields.items.subfields[n].item.textvalue}}</h4>
        <h2>
          【{{ tagged_values[n].letter_callname }}】 【{{
            tagged_values[n].letter_target
          }}】 【{{ tagged_values[n].letter_lang }}】
        </h2>

        <div class="confirmbox">
          <div class="previewbox">
            <div v-if="!previewImages.length">
              しばらくお待ちください。プレビューを読み込み中です..
            </div>
            <div v-else>

              
                
    <vue-easy-lightbox
      :escDisabled="false"
      :moveDisabled="false"
      closeText="閉じる"
      :visible="imgvisible(n).visible"
      :imgs="previewImages[n]"
      :index="imgvisible(n).index"
      @hide="imghide(n)"
    >
    
  <template v-slot:toolbar="{ toolbarMethods }">

    <div class="vel-toolbar" prefixcls="vel">
      <div class="toolbar-btn toolbar-btn__zoomin" @click="toolbarMethods.zoomIn"><svg aria-hidden="true" class="vel-icon icon"><use xlink:href="#icon-zoomin"></use></svg>拡大</div>
      <div class="toolbar-btn toolbar-btn__zoomout" @click="toolbarMethods.zoomOut"><svg aria-hidden="true" class="vel-icon icon"><use xlink:href="#icon-zoomout"></use></svg>縮小</div>
      
      </div>


  </template>
  
  </vue-easy-lightbox>



              <ul>
                <li v-for="(img, i2) in previewImages[n]" :key="i2">
                  <img class="letter_previewimg" :src="img" alt="" @click="imgview(n,i2)" />
                </li>
              </ul>
            </div>
          </div>

          <div class="detailbox">

<h3>お申込内容

<div class="optionviewbn" v-if="!viewmode">
            <span v-if="!visibled_form(i,1)" @click="visible_form(i,1)">
                変更する
            </span>
            <span class="okbn" v-else-if="visibled_form(i,1)" @click="unvisible_form">
                ✓ 変更を完了する
            </span>

</div>

</h3>


            <plugin_itemoptions
                      :visible_form="visibled_form(i,1)"
              :orderData="orderData"
              :options_filtered="options_filter('option1')"
              :cartIndex="n"
              :posterrors="errors_global"
            />

<h3>メッセージ
           

<div class="optionviewbn" v-if="!viewmode">
            <span v-if="!visibled_form(i,2)" @click="visible_form(i,2)">
                変更する
            </span>
            <span class="okbn" v-else-if="visibled_form(i,2)" @click="unvisible_form">
                ✓ 変更を完了する
            </span>

</div>

            </h3>
            
          <dl>
            <dd>
                    <plugin_itemoptions
                      :visible_form="visibled_form(i,2)"
                      :orderData="orderData"
                      :options_filtered="options_filter('option2')"
                      :cartIndex="n"
                      :posterrors="errors_global"
                    />
            </dd>
          </dl>


  <p class="discount" v-if="firstOfObject(computed_prop.delivers[i].items).discount">割引: -{{firstOfObject(computed_prop.delivers[i].items).discount}}円</p>

  <p class="price">単価: {{firstOfObject(computed_prop.delivers[i].items).price}}円(税込)</p>

  
<h3>オプション商品
  

<div class="optionviewbn" v-if="purchase_enabled&&!viewmode">
            <span v-if="!visibled_form(i,3)" @click="visible_form(i,3)">
                オプションを追加する
            </span>
            <span class="okbn" v-else-if="visibled_form(i,3)" @click="unvisible_form">
                ✓ オプションの追加を完了する
            </span>

</div>

</h3>

            <dl>
                  <dd>
                    <plugin_itemoptions
                      :visible_form="visibled_form(i,3)"
                      :orderData="orderData"
                      :options_filtered="options_filter('deliver1,deliver2')"
                      :cartIndex="n"
                      :posterrors="errors_global"
                    />
                  </dd>
                </dl>

			
            <h3>配送方法
                
<div class="optionviewbn" v-if="purchase_enabled&&!viewmode">
            <span v-if="!visibled_form(i,'deliver')" @click="visible_form(i,'deliver')">
               変更する
            </span>
            <span class="okbn" v-else-if="visibled_form(i,'deliver')" @click="unvisible_form">
                ✓ 変更を完了する
            </span>

</div>

            </h3>

<div v-if="visibled_form(i,'deliver')">
<dl>
				<dt>配送方法</dt>
				<dd>

<div class="delivers" v-for="(deliver,d) in computed_prop.delivers[i].delivermethods" :key="'d'+d">
   
   <label v-if="deliver.status">

 
            <input class="zoomcheck" :name="'deliver_method'+i" type="radio" 
           
            v-model="orderData.fields.mydeliver.subfields[i-0].type.value"
            :value="deliver.ID"
            
              />
              
              
  {{deliver.label}}
  ({{deliver.area}} /
  {{deliver.size}}
  ：{{deliver.cost}}円)</label>
  
  <div class="deliver_description" v-html="deliver.description" v-if="deliver.status && deliver.description"></div>

              </div>

</dd>
</dl>




			<dl>
				<dt>お届け希望日</dt>
				<dd>
				
                
                
<div v-if="!selectedDeliver(i)">

  配送日時を選択するには、まず配送方法を選択してください。
</div>
<div v-else>
<div class="arrive_selector" v-if="selectedDeliver(i).allowed_days.length">
お届け希望日 
<select name="" id="" v-model="orderData.fields.mydeliver.subfields[i].date.value">
  <option value="">指定しない</option>
  <option :value="d" v-for="d,di in selectedDeliver(i).allowed_days" :key="di">{{fetchCurrentDate("YYYY年M月D日", d)}}</option>
</select>
<br>
</div>
<div class="arrive_selector" v-else>
(ご選択いただいた配送方法は、お届け希望日の指定に対応していません。)
</div>


</div>


                   </dd>
			</dl>

			<dl>
				<dt>お届け希望時間帯</dt>
				<dd>
                    
                    
<div v-if="!selectedDeliver(i)">

  配送日時を選択するには、まず配送方法を選択してください。
</div>
<div v-else>

<div class="arrive_selector" v-if="selectedDeliver(i).allowed_times.length">
時間帯のご指定
<select name="" id="" v-model="orderData.fields.mydeliver.subfields[i].time.value">
  <option value="">指定しない</option>
  <option :value="d2" v-for="d2,di2 in selectedDeliver(i).allowed_times" :key="di2"> {{d2}}</option>
</select>
<br>
</div>
<div class="arrive_selector" v-else>
(ご選択いただいた配送方法は、お届け時間帯の指定に対応していません。)
</div>

</div>

                   </dd>
			</dl>


</div>

<div v-else v-for="(deliver, d2) in computed_prop.delivers[i].delivermethods" :key="d2"><div v-if="mydeliver.type.value == deliver.ID">
			<dl>
				<dt>配送方法</dt>
				<dd>
                    
                    <p v-if="deliver.status">
                      <label>
                        {{ deliver.label }}
                        ({{ deliver.area }} /
                        {{ deliver.size }})</label
                      >
                    </p>
                    <p v-else>
                        (選択されていません)
                    </p>
                    </dd>
			</dl>
      


			<dl v-if="mydeliver.date.value||visibled_form(i,4)">
				<dt>お届け希望日</dt>
				<dd>
					{{mydeliver.date.textvalue?mydeliver.date.textvalue:mydeliver.date.value}}
                   </dd>
			</dl>

			<dl v-if="mydeliver.time.value">
				<dt>お届け希望時間帯</dt>
				<dd>
					{{mydeliver.time.textvalue?mydeliver.time.textvalue:mydeliver.time.value}}
                   </dd>
			</dl>
            <!--
			<dl v-if="mydeliver.code.value">
				<dt>荷物追跡番号</dt>
				<dd>
					{{mydeliver.code.textvalue}}
                   </dd>
			</dl>
			
			<dl v-if="mydeliver.status.value">
				<dt>配送状況</dt>
				<dd>
					{{mydeliver.status.textvalue}}
                   </dd>
			</dl>
            -->
      <div class="price">配送料:{{ deliver.cost }}円</div>
			</div></div>

			<h3>お届け先の情報
        
        <span class="backlink" v-if="flow=='confirm'"><router-link @click.native="scrollToTop" :to="{ path: '/item/' + label + '/deliver#item'+(i+1), query: { rd: 'back' }}">編集</router-link></span>

      </h3>

            <dl>
				<dt>お届け先名<ordereditformbns :names="['mydeliver-'+i+'-sei','mydeliver-'+i+'-mei']" /></dt>
              <dd :class="{changed:form_changed(['mydeliver-'+i+'-sei','mydeliver-'+i+'-mei'])}">
                      <ordereditform :name="'mydeliver-'+i+'-sei'" display="inline" />　<ordereditform :name="'mydeliver-'+i+'-mei'" display="inline" />
                      
                    
					</dd>
			</dl>
			

            <dl v-if="mydeliver.sei_kana.value||mydeliver.mei_kana.value">
				<dt>お届け先名ふりがな<ordereditformbns :names="['mydeliver-'+i+'-sei_kana','mydeliver-'+i+'-mei_kana']" /></dt>
				<dd :class="{changed:form_changed(['mydeliver-'+i+'-sei_kana','mydeliver-'+i+'-mei_kana'])}">
                    
                      <ordereditform :name="'mydeliver-'+i+'-sei_kana'" display="inline" />　 <ordereditform :name="'mydeliver-'+i+'-mei_kana'" display="inline" />

                    </dd>
			</dl>

            <dl>
				<dt>お届け先住所<ordereditformbns :names="['mydeliver-'+i+'-zip','mydeliver-'+i+'-state','mydeliver-'+i+'-city','mydeliver-'+i+'-street','mydeliver-'+i+'-building','mydeliver-'+i+'-other']" /></dt>
				<dd :class="{changed:form_changed(['mydeliver-'+i+'-zip','mydeliver-'+i+'-state','mydeliver-'+i+'-city','mydeliver-'+i+'-street','mydeliver-'+i+'-building','mydeliver-'+i+'-other'])}">


                      <ordereditform :name="'mydeliver-'+i+'-zip'" label="郵便番号" pretext="〒" display="inline" size="8" /><br>

                      <ordereditform :name="'mydeliver-'+i+'-state'" label="都道府県" size="8"  />
                      <ordereditform :name="'mydeliver-'+i+'-city'" label="市町村" size="15"  />
                      <ordereditform :name="'mydeliver-'+i+'-street'" label="地域・番地" size="60" />　<ordereditform :name="'mydeliver-'+i+'-building'" label="アパート・マンション・建物名" size="60" />
                      <ordereditform :name="'mydeliver-'+i+'-other'" label="様方・会社名" size="60" />



				</dd>
			</dl>

            <dl>
				<dt>お届け先電話番号<ordereditformbns :names="['mydeliver-'+i+'-tel']" /></dt>
				<dd :class="{changed:form_changed(['mydeliver-'+i+'-tel'])}"><ordereditform :name="'mydeliver-'+i+'-tel'" size="20" /></dd>
			</dl>

			
          </div>
        </div>
      </div>
      </div>
      </div>
    <div v-else>
      <div class="loadercontent">
読み込み中です..</div>

    </div>
    </div>
  </transition>
</template>


<style scoped lang="scss">
$sp: 680px; // スマホ
@mixin sp {
    @media (max-width: ($sp)) {
        @content;
    }
}
h1{
  background: #D72E23;
  color: #fff;
}
.detailbox {
  line-height: 1.7;
  text-align: left;
  padding: 1em;
  flex-grow: 100;
  &.profiles{
    border:solid 1px #ccc;
    padding:2em;
    margin-top: 2em;
    @include sp{
      padding:5vw;
    }
  }
}
.detailbox dt {
  border-bottom: solid 1px #ccc;
  font-size: 0.8em;
  font-weight: bold;
  color: rgb(84, 83, 92);
    display: flex;
    justify-content: space-between;
    align-items:flex-end;
}
.detailbox dd{
    padding: .4em;
    font-size: .8em;
}
.detailbox dd.changed:before{
    content:"(変更する)";
    font-size:.7em;
    display: block;
    color: rgb(88, 88, 88);
    font-weight: bold;
}
.detailbox dd.changed{
    background:#6edfb0;
}
.detailbox dl {
  margin-bottom: 0.5em;
}
.previewbox {
    margin-top: 1em;
  background: rgb(245, 245, 245);
  text-align: left;
  padding: 0.5em;
  ul{
    list-style: none;
  }
}
.letter_previewimg {
  border: solid 1px #b0b0b0;
  width: 200px;
  cursor: pointer;
  &:hover{
    opacity: .8;
  }
}
.confirmbox {
  display: flex;
  max-width: 1000px;
  margin: 0 auto;
}
.deliverone{
  margin-top: 2em;
}
.deliver_description{
    font-size: .7em;
    border:solid 1px #ccc;
    border-radius: .7em;
    padding:1em;
    margin-top: .5em;
    margin-bottom: 2em;
}
h3{
    margin-top: 1em;
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
    flex-wrap: wrap;
}
.optionviewbn{
    display: flex;
    justify-content: flex-end;
    font-size: 1rem;
    font-weight: normal;
    span{
        display: inline-block;
        color:#0045F2;
        padding:.2em;
        padding-left:2.3em;
        border-radius: .3em;
        text-decoration: none;
        margin: 0 .2em .2em .2em;
        vertical-align: middle;
        background:  url("../assets/images/editable.png") no-repeat left center / contain;
        cursor: pointer;
        &.okbn{
            background:none;
            padding:.2em 1em;
            background: #0045F2;
            color:#fff;
        }
        &:hover{
            background-color: rgb(217, 220, 252);
        }
    }
    
}

@mixin sp {
    @media (max-width: (480px)) {
        @content;
    }
}
.vel-toolbar{
  display: flex;
  position: fixed;
  bottom: 5vh;
  width: 100%;
  justify-content: center;
  @include sp{
    font-size: 7vw;
  }
  svg{ 
    width: 1em;height: 1em;
  }
  .toolbar-btn{
    cursor: pointer;
    background: rgb(179, 179, 179);
    padding: .3em 1em;
    border-radius: .5em;
    margin-right: .6em;
    &:hover{
      opacity: .8;
    }
  }
}
.backlink{
  float: right;
  a{
    color: #fff;
    background: rgb(160, 160, 160);
    padding: .3em 1em;
    display: inline-block;
  text-decoration: none; font-size: .75em;
  border-radius: .7em;
  font-weight: normal;
  &:hover{
    background: red;
  }
  }
}
.price{
  background: rgb(245, 245, 245);
    text-align: right;
    font-size: .9em;
    font-weight: bold;
    color: rgb(71, 20, 20);
    margin-bottom: .5em;
}
.discount{
  background: rgb(227, 240, 255);
    text-align: right;
    font-size: .9em;
    font-weight: bold;
    color: rgb(71, 20, 20);
    margin-bottom: .5em;
}
</style>

<script>
import plugin_itemoptions from "@/components/itemoption_simple";
import ordereditform from "@/components/orderedit_form";
import ordereditformbns from "@/components/orderedit_formbns";
import VueEasyLightbox from 'vue-easy-lightbox'
export default {
  components: { plugin_itemoptions ,VueEasyLightbox,ordereditform,ordereditformbns },
  props: ["orderData","flow","options_prop","computed_prop"],
  data: function () {
    return {
      values: {},
      previewHTML: "",
      previewImages: [],
      tagged_values: [],
      //computed: {},
      imgviewflg:[],
      show_price:true,
      form_visibled:{},
      form_editting_names:[],
      //visibled_form_name:""
    };
  },
  created: function () {
    this.loaded_countmax = 2;
/*
    if (this.orderData.fields.prices.value){
      if(typeof this.orderData.fields.prices.value=="string"){
        this.computed = JSON.parse(this.orderData.fields.prices.value);
      }else{
        this.computed = this.orderData.fields.prices.value;
      }
    }*/

    this.get_itemoption_byid(this.orderData.fields.items.subfields[0].item.value).then(() => {
      this.values = this.orderData.fields.items.subfields;
      var tagged_values = [];
      var unit = this.orderData.fields.items.subfields.length;
      for (let i = 0; i < unit; i++) {
        let options = this.orderData.fields.items.subfields[i].item.options;
        //console.log("this.get_tagged_values(this.get_option_value(i))",this.get_tagged_values(this.get_option_value(i),options))
        tagged_values.push(this.get_tagged_values(this.get_option_value(i),options));
      }
      if(!this.options.length)this.options=this.options_prop;

    for(var t=0;t<tagged_values.length;t++){
        console.log("tagged_values",t,tagged_values[t]);
        if(typeof tagged_values[t].letternumber!="undefined" && /^SP[0-9]+/.test(tagged_values[t].letternumber)){
            this.$parent.show_price=false;
            console.log("申し込みセット",this.$parent)
        }
    }

      this.tagged_values = tagged_values;
      this.loaded_countup();
    });

    //var orderData=this.getSession("orderData");
    //まず商品定義を読み込む
    this.fetch("previewcreate", {
      label: this.label,
      record: this.orderData,
    }).then((data) => {
      console.log(data);
      //this.previewHTML=data;
      this.previewImages = data.preview;
      this.loaded_countup();
    });
  },
  computed: {

    //追加決済が可能かのフラグ
    //支払済みの場合は可能
    purchase_enabled: function () {
        return this.orderData.fields.order_status.value=="complete";
    },
    fields: function () {
      return this.orderData.fields;
    },
    label: function () {
      return this.$route.params.label;
    },
  },
  methods: {
      
    selectedDeliver:function(index){
      for(var i=0;i<this.computed_prop.delivers[index].delivermethods.length;i++){
        if(
          this.computed_prop.delivers[index].delivermethods[i].ID
          ==
          this.orderData.fields.mydeliver.subfields[index].type.value
          ){
            return this.computed_prop.delivers[index].delivermethods[i];
        }
      }
      return false;
    },
    
      visibled_form:function(i,name){
          return this.$parent.visibled_form_name===i+"-"+name;
      },
      visible_form:function(i,name){
          this.$parent.visibled_form_name=i+"-"+name;
      },
      unvisible_form:function(){
          this.$parent.visibled_form_name="";
      },
      form_getfield:function(name){
          if(name.indexOf("-")==-1){
              return this.fields[name];
          }else{
              let names=name.split("-");
              //console.log("getfield",names,this.fields[names[0]].subfields[names[1]-0][names[2]])
              //ただしmydeliverについては、実際の配列番号は、itemから指定されている並び順番号を指定する
              /*
              if(names[0]=='mydeliver'){
                  let mydeliver_index=this.fields["items"].subfields[names[1]-0]["deliver"].value;
                return this.fields[names[0]].subfields[mydeliver_index-0][names[2]];
              }else{
                return this.fields[names[0]].subfields[names[1]-0][names[2]];
              }
              */
             //いいえ、mydeliverの配列番号とitemの配列番号が必ず重なるように、予めサーバ上で補正したものを受け取るものとする。
                return this.fields[names[0]].subfields[names[1]-0][names[2]];
          }
      },
      form_getvalue:function(name){
          return this.form_getfield(name).value;
      },
      form_getoldvalue:function(name){
          return this.form_getfield(name).value_ordered;
      },
      form_change:function(){
          let names=this.form_editting_names;
          for(let i=0;i<names.length;i++){
              if(typeof this.form_getfield(names[i]).value_update!="undefined")
            this.$set(this.form_getfield(names[i]),"value",this.form_getfield(names[i]).value_update);
          }
          console.log("セット完了",this.fields);
          this.form_unvisible(names);
      },
      form_prechange:function(name,val){
          this.form_getfield(name).value_update=val;
          console.log("変化します",this.fields)
      },
      form_changed:function(names){
          let changed=false;
          for(let i=0;i<names.length;i++){
              let newval1=this.form_getfield(names[i]).value;
              let oldval2=this.form_getfield(names[i]).value_ordered;
              if(newval1!=oldval2){changed=true;break;}
          }
          //console.log("form_changed A",changed)
          return changed;
      },
      form_getvisible:function(names){
          //console.log("form_getvisible",names[0],this.form_visibled)
           return this.form_visibled[names[0]];
         // for(let i=0;i<names.length;i++){
         //   if(this.form_visibled,names[i],true);
         // }
      },
      form_unvisible:function(names){
          console.log(names);
          //this.$set(this.form_visibled,name,false);
          this.form_visibled={};
      },
      form_visible:function(names){
          this.form_visibled={};
          for(let i=0;i<names.length;i++){
          this.$set(this.form_visibled,names[i],true);
          }
          this.form_editting_names=names;
          console.log("this.form_visibled",this.form_visibled)
      },
    firstOfObject:function(val){
      if(Array.isArray(val)){
        return val[0];
      }else{
        return val[Object.keys(val)[0]];
      }
    },
    imgvisible:function(index){
      if(typeof this.imgviewflg[index]=="undefined"||!this.imgviewflg[index]){
        return {index:0,visible:false};
      }
      return this.imgviewflg[index];
    },
    imghide:function(index){
      this.$set(this.imgviewflg,index, {index:0,visible:false});
    },
    imgview:function(index,imgindex){
      this.$set(this.imgviewflg,index, {index:imgindex,visible:true});
    },
    get_option_value(index) {
      if (this.values[index].options.value) {
        return JSON.parse(this.values[index].options.value);
      } else {
        return [];
      }
    },
  },
};
</script>