<template>
  <div>
    
   <h1>注文内容の変更</h1>
    <p v-if="viewmode!='complete'&&logined&&loaded" >こんにちは、  {{common.mydata.sei.value}}   {{common.mydata.mei.value}}さん</p>

<div v-if="viewmode=='complete'">

<div class="notice">
  ありがとうございます。ご注文内容の変更が完了しました。


<ul class="backlinks">
  <li>
             <router-link @click.native="scrollToTop" :to="'/status/'+orderData.fields.ordertoken.value">変更したご注文内容の確認へ</router-link> 
             </li><li>
             <router-link @click.native="scrollToTop" to="/mypage/order_history">ご注文履歴一覧へ戻る</router-link> 

</li>
</ul>
  </div>
</div>

<div v-else-if="loaded&&logined">

<div v-if="!update_enabled">

<p class="notice">
ご指定のご注文は、現在ご注文内容の変更が行えません。
</p>

</div>


<div v-else>

<div v-if="viewmode==''" class="headtitle">
    <div>
    <p class="notice">
以下より、ご注文内容の変更を行うことができます。なお、変更はご注文より所定の期間内のみ行うことができます。<br>
すでにご注文のお支払い済みの場合は、追加の決済を伴う変更も可能です。(追加の決済はクレジットカードのみ承ります)<br><br>
また、商品が出荷準備に入った場合には、変更ができなくなりますのでご了承ください。
</p>


    <p class="notice">
以下の各内容の横にある<span class="editbn"></span>ボタンをクリックすると、内容を変更することができます。<br>アイコンが表示されない部分は変更いただけませんのでご了承ください。
<br>
変更を完了したら、「変更内容を確認する」をクリックしてください。
</p>



<div class="footbns">
<a class="bn_back" @click.prevent="back">変更をキャンセル</a>
<a class="bn_confirm" @click.prevent="confirm">変更内容を確認する</a>
</div>



    </div>

    </div>
    
<div class="headtitle" v-else-if="viewmode=='payment'">
  <strong>変更は完了していません。</strong><br>
  以下の通り、<strong>合計金額が変更されまます。変更を確定するには、追加のお支払い(差額分)が必要です</strong><br>
  以下の内容でよろしければ、<strong>このページの最下部にあるお支払い情報をご入力いただき、「変更内容を確定する」ボタン</strong>を押してください。

  

<div class="footbns">
<a class="bn_back" @click.prevent="viewmode=''">変更しなおす</a>
<a class="bn_next" @click.prevent="submit_payment">変更内容を確定する</a>
</div>


</div>

    
<div class="headtitle" v-else-if="viewmode=='confirm'">
  <strong>まだ変更は完了していません。</strong><br>
  以下の内容でよろしければ、<strong>「変更内容を確定する」ボタン</strong>を押してください。

  

<div class="footbns">
<a class="bn_back" @click.prevent="viewmode=''">変更しなおす</a>
<a class="bn_next" @click.prevent="submit">変更内容を確定する</a>
</div>


</div>



</div>


    
    <div class="itemcompute">
       
      <dl class="message" v-if="payment_info">
        <dt>ご注文番号</dt>
        <dd>{{orderData.fields.orderno.value}}</dd>
      </dl>
      <dl class="message" v-if="payment_info">
        <dt>ご注文日時</dt>
        <dd>{{orderData.page_newdate}}</dd>
      </dl>
      <dl class="message" v-if="payment_info">
        <dt>お支払い方法</dt>
        <dd>{{orderData.fields.payment_type.textvalue}} <span v-if="payment_info.addfound">(追加決済あり)</span>


        </dd>
      </dl>

<div v-if="show_price">
      <dl>
        <dt>商品金額合計</dt>
        <dd v-if="newprice('price_taxation')">(変更) <strike>{{ computed.price_taxation }}円</strike>→{{ computed_new.price_taxation }}円<span class="tax">{{computed.taxview}}</span></dd>
        <dd v-else>{{ computed.price_taxation }}円<span class="tax">{{computed.taxview}}</span></dd>
      </dl>
      <dl>
        <dt>合計配送料</dt>
        <dd v-if="newprice('delivercost')">(変更) <strike>{{ computed.delivercost }}円</strike>→{{ computed_new.delivercost }}円</dd>
        <dd v-else>{{ computed.delivercost }}円</dd>
      </dl>
      <dl v-if="computed.payment_customdeliver!=0">
        <dt>カスタム配送料</dt>
        <dd>{{ computed.payment_customdeliver }}円</dd>
      </dl>
      <dl v-if="computed.payment_fee!=0">
        <dt>手数料</dt>
        <dd>{{ computed.payment_fee }}円</dd>
      </dl>

        <dl v-if="computed.campains && computed.campains.length">
          <dt>適用されたキャンペーン</dt>
          <dd v-for="campain,c1 in computed.campains" :key="c1">
            <div class="camp_title">{{ campain.title }}</div>
            <!--<div class="camp_desc">{{ campain.description }}</div>-->

          </dd>
        </dl>

      <dl v-if="computed.discount!='0'">
        <dt>割引</dt>
        <dd>{{ computed.discount }}円</dd>
      </dl>
      <dl>
        <dt v-if="computed.taxtype == 'include'">(うち消費税)</dt>
        <dt v-if="computed.taxtype == 'exclude'">消費税</dt>
        <dd v-if="newprice('tax')">(変更) <strike>{{ computed.tax }}円</strike>→{{ computed_new.tax }}円</dd>
        <dd v-else>{{ computed.tax }}円</dd>
      </dl>
      <dl>
        <dt>合計金額</dt>
        <dd v-if="newprice('total_amount')">(変更) <strike>{{ computed.total_amount }}円</strike>→{{ computed_new.total_amount }}円</dd>
        <dd v-else><strong>{{ computed.total_amount }}円<span class="tax">{{computed.taxview}}</span></strong></dd>
      </dl>
      <dl class="price_add" v-if="newprice('total_amount')">
        <dt>差額</dt>
        <dd>{{ computed_new.price_diff }}円
          <span v-if="computed_new.price_diff>0">(こちらが、追加のお支払いが必要な金額です)</span>
          <span v-if="computed_new.price_diff<0">(×ご注文の変更時には追加決済のみ承ります)</span>
          </dd>
      </dl>

</div>    </div>



    <orderview :orderData="orderData" :options_prop="options" :computed_prop="computed"></orderview>


<div v-if="viewmode==''">

<div class="footbns">
<a class="bn_back" @click.prevent="back">変更をキャンセル</a>
<a class="bn_confirm" @click.prevent="confirm">変更内容を確認する</a>
</div>
</div>


<div v-else-if="viewmode=='payment'">
 <strong>変更を確定するには、追加のお支払い(差額分)が必要です</strong><br>
  以上の内容でよろしければ、<strong>お支払い情報をご入力いただき、「変更内容を確定する」ボタン</strong>を押してください。

  

<payment ref="payment" :orderData="orderData" :computed_prop="computed_new"></payment>
  

<div class="footbns">
<a class="bn_back" @click.prevent="viewmode=''">変更しなおす</a>
<a class="bn_next" @click.prevent="submit_payment">変更内容を確定する</a>
</div>


</div>


    
<div v-else-if="viewmode=='confirm'">
  以下の内容でよろしければ、<strong>「変更内容を確定する」ボタン</strong>を押してください。

  

<div class="footbns">
<a class="bn_back" @click.prevent="viewmode=''">変更しなおす</a>
<a class="bn_next" @click.prevent="submit">変更内容を確定する</a>
</div>


</div>


<hr>

<partmenu />
</div>
<fullloader :loading="!loaded||submitloading" />

<div class="totopbn">[<router-link to="/">トップページへ戻る</router-link>]</div>

    <login v-if="!logined" v-model="logined" />




    <dialogalert
      v-model="visible_alert"
      :title="alert_title"
      :info="alert_info"
      button_ok="OK"
    />



  </div>
</template>

<script>
import partmenu from '@/components/part_menu.vue'; 
import orderview from "@/components/orderedit.vue";
import payment from "@/components/payment.vue";

export default  {
  components: {
    partmenu, orderview,payment
  },
  watch:{
    loaded:function(v){
      console.log("完了")
    }
  },
  data: function () {
    return {
      visible_alert:false,
      alert_title:"",
      alert_info:"",

      orderData: "",
      computed:{},
      computed_new:{},
      payment_info:{},
      imgviewflg:[],
      options:[],
      statusmsg:"",
      show_price:true,
      update_enabled:false,
      visibled_form_name:"",
      submitloading:false,
    };
  },
  created:function(){
    this.$parent.middle = 2; 
    
    this.loaded_countmax=2;
    if(!this.common.mydata){
      this.login("mypage").then(()=>{
          this.loaded_countup();
          this.loadorder();
      }).catch((data) => {console.log("失敗いい")
          this.loaded_countmax=1;
          this.loaded_countup();
      });
    }else{
          this.loaded_countup();
          this.loadorder();
    }
  },
  computed:{
      status:function(){
        //決済完了の場合は、発送完了を判別する
        if(this.orderData.fields.order_status.value=="complete"){
          //発送すべき数
          var maxpost=this.orderData.fields.mydeliver.subfields.length;
          var posted=0;
          for(var i=0;i<this.orderData.fields.mydeliver.subfields.length;i++){
            console.log("ステータス",this.orderData.fields.mydeliver.subfields[i].status.value);
            if(this.orderData.fields.mydeliver.subfields[i].status.value=="posted"){
              posted++;
            }
          }
          if(maxpost==posted){
            return "delivered";
          }else{
            this.statusmsg=maxpost+"つのうち、"+posted+"つの商品をすでに発送済みです。下部の各配送状況をご覧ください";
            return "half-delivered";
          }
        }
        return this.orderData.fields.order_status.value;
      }
  },
  methods:{
    //変更時新旧の差額がある場合は返す
    newprice:function(name){
      if(typeof this.computed_new[name]==="undefined")return 0;
      if(this.computed_new[name]===this.computed[name])return 0;
      return this.computed_new[name];
    },

    back:function(){
      this.scrollToTop();
      this.$router.push('/mypage/order_history');
    },
post_submit:function(mode,fn){


//this.loaded_countmax++;
this.submitloading=true;


      var record2 = this.orderData;
      //ログイン済みの場合はトークン、非会員の場合はuserを「-1」にする。
      var token = this.get_localStorage("token");
      if (!token) record2.user = "-1";

      let cardtoken="";
      console.log("this.$refs")
      console.log("this.$refs.payment",this.$refs.payment)
      if(typeof this.$refs.payment !="undefined"){
        cardtoken=this.$refs.payment.cardToken.token;
        console.log("cardtoken",cardtoken);
        }

      this.fetch("order_submit", {
        token: token,
        cardtoken:!!cardtoken?cardtoken:"" ,
        record: record2,
        mode: mode,
      }).then((data) => {
        
          //this.loaded_countup();
            this.submitloading=false;
           this.computed_new=data.computed;
           
           this.computed_new.price_diff=this.computed_new.total_amount - this.computed.total_amount;
        
        if (typeof data.error != "undefined") {
          console.log(data.error);
          this.visible_error = true;
          this.error_info =
            "以下の理由のため、完了できませんでした。<br>以下の内容をご確認の上、内容を修正してください。<hr>";
         
         
          for (let i = 0; i < data.error.length; i++) {
            this.error_info += "・ " + data.error[i].msg + "<br>";
            /*if (data.error[i].id == "field_payment_cardtoken") {
              this.setSession("cardToken", {});
              
            }*/
          }

          
          this.visible_alert=true;
          this.alert_title="変更できませんでした";
          this.alert_info=this.error_info;
          //if(this.jumpurl) this.$router.push(jumpurl);
        

          }else if (typeof data.confirm != "undefined") {console.log(data.confirm)
            if(data.confirm[0].id=="prices_changed"){
              this.viewmode="payment";
            }
          } else {

          
          console.log("編集後の代金計算",data.computed)

          if(typeof this.$refs.payment !="undefined"){
          console.log("カードを削除")
            this.$refs.payment.cardDelete();
          }

            if(this.computed.taxtype == 'include')this.computed.taxview='(税込)';
            if(this.computed.taxtype == 'exclude')this.computed.taxview='(税別)';
            /*
          if(this.orderData.fields.payment_info.value){
            let payment_info=JSON.parse(this.orderData.fields.payment_info.value);
            this.payment_info=payment_info[payment_info.length-1];
          }
          this.options=this.orderData.fields.items.subfields[
*/

          this.viewmode="confirm";


          //成功したのでデータを削除
          //this.clearAllCart_exec();
          console.log("成功した");
          //ルーティングだと、残った変数を使用してlocalstrageが更新保存されてしまうので、
          //情報を空にするためにリダイレクトで移動する
          if(typeof fn=="function")fn(data);


        }
      });

},

  submit_successed:function(data){
    //alert("成功");
    console.log("成功",data,this.computed);
    this.viewmode="complete";
  },

    submit:function(){
      //alert("そうしんした");
      //this.$refs.payment[0].submit()

      
      this.visibled_form_name="";
      this.scrollToTop();
      console.log("さて結果は");

      
     this.post_submit("submit",this.submit_successed);

      /*
                let record = JSON.parse(JSON.stringify(this.orderData));
                this.fetch("load_price", { record: record }).then((data) => {
                    this.computed=data;
                    console.log(data)
                });
*/




    },
    submit_payment:function(){
this.submitloading=true;
      //alert("そうしんするぞ");
      //cardtoken: cardToken ? cardToken.token : "",
      console.log(this.$refs.payment)
      this.$refs.payment.submit();

    },
    confirm:function(){
      this.visibled_form_name="";
      this.scrollToTop();
      console.log("さて結果は");

      /*
                let record = JSON.parse(JSON.stringify(this.orderData));
                this.fetch("load_price", { record: record }).then((data) => {
                    this.computed=data;
                    console.log(data)
                });
*/


      this.post_submit("check");



    },
    loadorder:function(){
        
      this.fetch("order_status", {
        ID:this.$route.params.ID
        //ordertoken: this.$route.params.ordertoken,
      }).then(
        function (data) {


        if (typeof data.error != "undefined") {
          console.log(data.error);
          this.visible_error = true;
          this.error_info =
            "以下の理由のため、読み込みできません。<hr>";
         
         
          for (let i = 0; i < data.error.length; i++) {
            this.error_info += "・ " + data.error[i].msg + "<br>";
            /*if (data.error[i].id == "field_payment_cardtoken") {
              this.setSession("cardToken", {});
              
            }*/
          }

          
          this.visible_alert=true;
          this.alert_title="変更できませんでした";
          this.alert_info=this.error_info;
         this.loaded=true;
          return
        }

          console.log("data", data);
          console.log("読み込み時の代金計算",JSON.parse(data.fields.prices.value))

          //いずれかのフラグに値がある場合は注文の変更が不可能
          let update_enabled=true;
          for(let i=0; i < data.fields["items"].subfields.length;i++){
             for(let i2 in data.fields["items"].subfields[i]){
               if(i2.indexOf("flag_")!=-1 && data.fields["items"].subfields[i][i2].value){
                  update_enabled=false;
                  break;
               }
             }
          }
          //注文状況が失敗やキャンセルの場合は不可
          if(!data.fields["order_status"].value=="order" && !data.fields["order_status"].value=="complete"){
             update_enabled=false;
          }
         this.update_enabled=update_enabled;
          
          

      for(let n in data.fields){
          //  console.log("対象：",n);
        //サブフィールド以外はそのまま代入
        if(typeof data.fields[n].value=="string"){
              data.fields[n].value_ordered=data.fields[n].value;
        
        }else if(typeof data.fields[n].subfields!="undefined"){
          
          for(let n2=0; n2 < data.fields[n].subfields.length;n2++){
            for(let n4 in data.fields[n].subfields[n2]){
              data.fields[n].subfields[n2][n4].value_ordered=data.fields[n].subfields[n2][n4].value;
            }
          }
        }
      }
        this.orderData=data;
          
          if(this.orderData.fields.prices.value){
            this.computed=JSON.parse(this.orderData.fields.prices.value);
            if(this.computed.taxtype == 'include')this.computed.taxview='(税込)';
            if(this.computed.taxtype == 'exclude')this.computed.taxview='(税別)';
          }
          if(this.orderData.fields.payment_info.value){
            
            let payment_info=JSON.parse(this.orderData.fields.payment_info.value);
            //支払情報配列より、直近の、追加決済など以外の通常決済を取得する。通常決済はflowが未定義又は空白
              for(let i=payment_info.length-1; i>=0;i--){
                if(!!!payment_info[i].flow||i==0){
                  this.payment_info=payment_info[i];break;
                }
              }

            let addfound=false;
              for(let i=payment_info.length-1; i>=0;i--){
                if(payment_info[i].flow=="add"){
                  addfound=true;
                }
              }
              this.payment_info.addfound=addfound;
          }
          this.options=this.orderData.fields.items.subfields[0].item.options;
          console.log("this.optionsを定義",this.options);
         this.loaded_countup();
        }.bind(this)
      );
    }
  },
}
</script>

<style scoped lang="scss">
hr{
  margin-bottom: 5em;
}
h1{
  margin: 1em 0;
}
.headtitle{
  margin-top: 1.5em;
  p.notice{
  margin-bottom: 1.5em;
  line-height: 1.9;
  font-size: .9em;
  }
  p.notice2{
  margin-bottom: 1.5em;
  line-height: 1.9;
  font-size: .75em;
  text-align: left;
  }
}
ul.backlinks{
  list-style: none;
  width: 20em;
  max-width: 90%;
  margin: 1em auto;
  li{
    a{
      display: block;
      border:solid 1px #ccc;
      margin-bottom: .5em;
      border-radius: .6em;
      padding: .7em 0;
      text-decoration: none;
      &:hover{
        background: rgb(240, 238, 255);
      }
    }

  }
}
.price_add dd{
  font-weight: bold;
  font-size: 1.2em;
  color: #D72E23;
}
.content{
  color: #000;
}
.itemcompute{
  font-size: .8em;
}
.itemcompute dl{
    padding: 1em;
    display: flex;
    border-bottom: solid 1px #ccc;
}
.itemcompute dl dt{
    width: 15em;
}

.camp_title{
  font-size: .8em;
  color: #383838;
  font-weight: bold;
  padding: .7em;
  text-align: left;
}
.camp_desc{
  background: rgb(251, 251, 251);
  border-radius: .6em;
  padding: .7em;
  font-size: .7em;
  text-align: left;
}
.editbn{
  display: inline-block;
  width:2.3em;
  height:2.3em;
  border-radius: .3em;
  color: #fff;
  text-decoration: none;
  margin: 0 .2em .2em .2em;
  vertical-align: middle;
  background:  url("../../assets/images/editable.png") no-repeat center / contain;
}

</style>